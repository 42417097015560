import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/login/lazy-load.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/styles/loading.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/molecules/styles/card.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/molecules/styles/img.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/molecules/styles/title.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/styles/draggable-slide.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/skeleton-loader/styles/skeleton-loader.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/skeleton-loader/styles/order-details.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/templates/layout/lazy-load.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/utils/placeholder/Beauty.png");
import(/* webpackMode: "eager" */ "/app/src/services/utils/placeholder/Food.png");
import(/* webpackMode: "eager" */ "/app/src/services/utils/placeholder/Healthcare.png");
import(/* webpackMode: "eager" */ "/app/src/services/utils/placeholder/medicine.png");
import(/* webpackMode: "eager" */ "/app/src/services/utils/placeholder/Pet & vet.png");
import(/* webpackMode: "eager" */ "/app/src/services/utils/placeholder/Undefine_image.png")